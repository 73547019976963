
import { ref, computed, watch, UnwrapRef, onMounted, nextTick } from "vue";
import processors from "@/processors.json";
import { plan, email, isPaid } from "@/refs/account";
import { getJobEmoji } from "@/lib/v2";
import { config as arranConfig } from "@/config";

import router from "../router";
import models from "@/models.json";
import { enabledJobTypes, isFreeEnabled } from "@/lib/enabled-job-types";
import { getModelTime } from "@/lib/model-utils";
import { getExplorerToken } from "@/lib/explorer-token";
import { loadedAssets } from "@/lib/model-assets";

import ModelTags from "@/components/ModelTags.vue";

import "highlight.js/lib/common";
import hljsVuePlugin from "@highlightjs/vue-plugin";

// Import specific highlight.js theme CSS files
import "highlight.js/styles/github.css"; // Light theme
import "highlight.js/styles/github-dark.css"; // Dark theme

// Add this type declaration before the explorer assignment
declare global {
	interface Window {
		explorer: any;
	}
}

const inferenceUrl = ref<string>(
	process.env.VUE_APP_INFERENCE_URL || "https://inference.prodia.com"
);

export default {
	components: {
		highlightjs: hljsVuePlugin.component,
		ModelTags
	},
	setup: () => {
		const modelType = computed<string>(
			() => router.currentRoute.value.params.type as string
		);

		const jobTypes = computed(() =>
			enabledJobTypes.filter((type) =>
				type.startsWith(
					`inference.${modelType.value.replaceAll("/", ".")}`
				)
			)
		);

		const selectedJobType = ref<UnwrapRef<typeof jobTypes>[number]>(
			jobTypes.value.find((type) => type.startsWith("txt2")) ||
				(jobTypes.value[0] as string)
		);

		// url to default output example
		const selectedJobExample = computed<string | undefined>(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			console.log("selectedJobType", selectedJobType.value);

			console.log("processor", processor);
			// @ts-ignore
			const type = processor?.types[selectedJobType.value];

			console.log("type", type);

			console.log("type.accept.example", type?.accept?.example);

			const example = type?.accept?.example;

			if (example) return example;

			const model = models.find(
				(model) => model?.type === selectedJobType.value
			);

			const modelsPageExample = model
				? loadedAssets.value[model.filename] ||
				  `/assets/models/${model.filename}`
				: undefined;

			console.log({
				modelsPageExample,
				model
			});

			responseType.value = model?.media as ResponseType;

			return modelsPageExample;
		});

		type JobInput = {
			label: string;
			type: string[];
			maxSize: number;
			maxWidth: number;
			maxHeight: number;
			minWidth: number;
			minHeight: number;
			description: string;
			filename?: string;
		};

		const selectedJobInputs = computed<JobInput[]>(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			return processor?.types[selectedJobType.value]?.inputs || [];
		});

		const config = ref<Record<string, any>>({});
		const imageInputs = ref<File[]>([]);

		const selectedProperties = computed(() => {
			const processor = processors.find(
				(processor) => selectedJobType.value in processor.types
			);

			// @ts-ignore
			const flatternProperties = (schema: any) => {
				console.log("flatternProperties", schema);

				if ("properties" in schema)
					return flatternProperties(schema.properties);

				if ("allOf" in schema)
					return Object.assign(
						{},
						...schema.allOf.map(flatternProperties).flat()
					);

				if ("oneOf" in schema) {
					console.log("oneOf", schema.oneOf);

					return (
						schema?.oneOf?.find((s: any) =>
							s?.properties?.type?.enum?.includes(
								selectedJobType.value
							)
						) ||
						schema?.oneOf?.[0] ||
						{}
					);
				}

				return schema;
			};

			if (processor === undefined) return undefined;

			const schema = flatternProperties(processor.schema);

			console.log(schema);

			const _config = schema?.config || schema?.properties?.config || {};

			console.log("_config", { _config });

			return flatternProperties(_config);
		});

		const defaultConfig = computed<Record<string, any>>(() => {
			const defaultConfig = {};

			for (const key in selectedProperties.value) {
				const defaultValue =
					selectedProperties.value[key]?.default ||
					selectedProperties.value[key]?.examples?.[0] ||
					undefined;

				// @ts-ignore
				if (defaultValue !== undefined && key !== "seed") {
					// don't set a default value for seeds
					// @ts-ignore
					defaultConfig[key] = defaultValue;
				}
			}

			return defaultConfig;
		});

		watch(defaultConfig, () => {
			config.value = {};
			imageInputs.value = [];

			console.log(defaultConfig.value);

			for (const key in defaultConfig.value) {
				if (typeof defaultConfig.value[key] === "boolean") {
					config.value[key] = defaultConfig.value[key];
				}
			}

			for (const key in selectedProperties.value) {
				const property = selectedProperties.value[key];

				if (property.type === "string" && property.enum) {
					config.value[key] = property.enum[0];
				}
			}

			console.log(config.value);

			image.value = undefined;
			imageFile.value = undefined;

			responseType.value = selectedJobType.value.includes("vid")
				? "video"
				: "image";
		});

		const payload = computed(() => {
			return {
				type: selectedJobType.value,
				config: {
					...defaultConfig.value,
					...config.value
				}
			};
		});

		type ResponseType = "image" | "video";

		const token = ref(localStorage.getItem("v2-token") || "");
		const remember = ref<boolean>(token.value !== "");
		const image = ref<string | undefined>(undefined);
		const responseType = ref<ResponseType>(
			selectedJobType.value?.includes("vid") ? "video" : "image"
		);
		const isGenerating = ref<boolean>(false);
		const generationTime = ref<number | undefined>(0);

		const imageFile = ref<File | undefined>(undefined);
		const logs = ref<string>("");

		// only use free captcha method for non pro+
		const shouldUseFree = computed(
			() => isFreeEnabled(selectedJobType.value) && !isPaid.value
		);

		const loadingBar = ref<number | undefined>();

		let loadingBarInterval: any;

		const startLoadingBar = () => {
			// setup loading bar et
			const startTime = Date.now();

			loadingBarInterval = setInterval(() => {
				const eta =
					parseFloat(getModelTime(selectedJobType.value)) * 1000;

				console.log("eta", eta);

				const timeElapsed = Date.now() - startTime;
				loadingBar.value = timeElapsed / eta;
				console.log("loadingBar", loadingBar.value);
			}, 10);

			loadingBar.value = 0;
		};

		const stopLoadingBar = () => {
			clearInterval(loadingBarInterval);
			loadingBar.value = undefined;
		};

		const run = async () => {
			// wipe logs
			logs.value = "";

			if (isGenerating.value === true) {
				return;
			}

			// disallow further generations
			isGenerating.value = true;

			// start generation, fail gracefully
			try {
				// setup form data
				const formData = new FormData();

				const jobBlob = new Blob([JSON.stringify(payload.value)], {
					type: "application/json"
				});

				for (const input of imageInputs.value) {
					const index = imageInputs.value.indexOf(input);
					const filename = selectedJobInputs.value[index]?.filename;

					formData.append("input", input, filename || "image.jpg");
				}

				formData.append("job", jobBlob, "job.json");

				let response;

				if (shouldUseFree.value) {
					// use free demo proxy
					const captchaToken = await getCaptchaToken();

					startLoadingBar();

					response = await fetch(
						`${
							arranConfig.arranUrl
						}/inference/job?${new URLSearchParams({
							token: captchaToken
						})}`,
						{
							method: "POST",
							headers: {
								Accept: "multipart/form-data"
							},
							body: formData
						}
					);
				} else {
					// user is pro+, get proper identity token
					token.value = await getExplorerToken();

					startLoadingBar();

					response = await fetch(`${inferenceUrl.value}/v2/job`, {
						method: "POST",
						headers: {
							Accept: "multipart/form-data",
							Authorization: `Bearer ${token.value}`
						},
						body: formData
					});
				}

				if (response.status === 429) {
					// avoid recursion conflicts
					setTimeout(run, 1000);
					return;
				}

				const body = await response.formData();

				const jobResponse = JSON.parse(
					new TextDecoder().decode(
						await (body.get("job") as Blob).arrayBuffer()
					)
				);

				logs.value = JSON.stringify(jobResponse, null, "\t");

				for (const [key, value] of body.entries()) {
					console.log("body", { key, value });
				}

				const jobJson = body.get("job");

				generationTime.value = jobResponse.metrics.elapsed * 1000;

				// get image data
				const imageBlob = body.get("output") as File;

				responseType.value = imageBlob.type.includes("image")
					? "image"
					: "video";

				const imageUrl = URL.createObjectURL(imageBlob);

				image.value = imageUrl;

				imageFile.value = new File(
					[imageBlob],
					selectedJobType.value.includes("vid")
						? "video.mp4"
						: "image.jpg",
					{
						type: imageBlob.type
					}
				);

				console.log("imageFile", imageFile.value);
			} finally {
				stopLoadingBar();
				isGenerating.value = false;
			}
		};

		const copyCurl = () => {
			const curlCommand = `curl --request POST \\
--url https://inference.prodia.com/v2/job \\
     --header 'accept: image/jpeg' \\
     --header 'content-type: application/json' \\
	 --header 'authorization: Bearer ${token.value}' \\
     --data '
${JSON.stringify(payload.value, null, "\t")}
' \\
--output image.jpg
`;

			navigator.clipboard.writeText(curlCommand);
		};

		const handleFileUpload = (event: Event, index: number) => {
			const target = event.target as HTMLInputElement;

			if (target.files && target.files.length > 0) {
				const file = target.files[0];

				if (file) {
					imageInputs.value[index] = file;
				}
			}
		};

		const selectedTab = ref<"explore" | "code" | "logs" | "admin">(
			"explore"
		);

		const javascriptCode = computed(() => {
			return `import fs from "node:fs/promises";
import { createProdia } from "prodia/v2";

const prodia = createProdia({
	token: process.env.PRODIA_TOKEN${
		selectedJobType.value.includes("mochi")
			? `,
\tmaxRetries: Infinity`
			: ""
	}
});

(async () => {
	${
		selectedJobInputs.value.length > 0
			? `const inputs = [${selectedJobInputs.value
					.map(
						(input) =>
							`\n\t\tawait fs.readFile("../${input.label}.jpg")`
					)
					.join(",")}
	];\n\n\t`
			: ""
	}const job = await prodia.job(${JSON.stringify(payload.value, null, "\t")
				.split("\n")
				.map((l, i) => (i === 0 ? l : "        " + l))
				.join("\n")}, {
		accept: ${JSON.stringify(
			selectedJobType.value.includes("vid") ? "video/mp4" : "image/jpeg"
		)}${selectedJobInputs.value.length > 0 ? `,\n\t\tinputs: inputs` : ""}
	});

	const image = await job.arrayBuffer();

	await fs.writeFile(${JSON.stringify(
		selectedJobType.value.includes("vid") ? "output.mp4" : "output.jpg"
	)}, new Uint8Array(image));
})();`;
		});

		// Add computed property for current theme
		const isDarkMode = computed(
			() => document.body.getAttribute("data-mode") === "dark"
		);

		const share = async () => {
			if (imageFile.value === undefined) return;

			if (!navigator.canShare({ files: [imageFile.value] })) return;

			const shareText = `${
				config.value.prompt ? `“${config.value.prompt}” ` : ""
			}https://app.prodia.com/explorer`;

			await navigator.share({
				files: [imageFile.value],
				title: "Generated with Prodia",
				text: shareText
			});
		};

		const isAdmin = computed(() => {
			return !!email.value?.endsWith("@prodia.com");
		});

		const environment = computed(() => {
			return inferenceUrl.value.includes("staging")
				? "Staging"
				: "Production";
		});

		const admin = {
			useStaging: () => {
				inferenceUrl.value = "https://inference.staging.prodia.com";
			},

			useProduction: () => {
				inferenceUrl.value = "https://inference.prodia.com";
			}
		};

		const captchaId = ref<string | undefined>(undefined); // unique id per captcha

		const getCaptchaToken = async () => {
			captchaId.value = `captcha-${Math.random()
				.toString(36)
				.substring(2, 15)}`;

			await nextTick();

			const token = await new Promise((resolve) => {
				// @ts-ignore
				turnstile.render(`#${captchaId.value}`, {
					sitekey:
						location.host === "app.prodia.com"
							? "0x4AAAAAAA5ZvxvqtMrmOVJW"
							: "0x4AAAAAAA5ZwLrxa7zPRacs",
					callback: resolve
				});
			});

			captchaId.value = undefined;

			if (typeof token !== "string") {
				throw new Error("Bad token value!");
			}

			return token;
		};

		onMounted(() => {
			// @ts-ignore
		});

		return {
			selectedTab,
			plan,
			jobTypes,
			selectedJobType,
			selectedJobExample,
			selectedProperties,
			config,
			payload,
			token,
			remember,

			image,
			run,
			isGenerating,
			generationTime,
			copyCurl,
			imageInputs,
			handleFileUpload,
			responseType,
			javascriptCode,
			isDarkMode,
			share,
			getJobEmoji,
			selectedJobInputs,
			logs,
			isAdmin,
			admin,
			environment,
			inferenceUrl,
			loadingBar,
			captchaId
		};
	}
};
